import { LoadingOutlined } from '@ant-design/icons';
import { get, useGlobalStore } from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Select, StepProps, Steps } from 'antd';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { getUseShopQuoteStoreByCode } from '../../../../../stores';
import styles from './index.module.less';

export interface ShopNewQuoteStepsProps {
  className?: string;
  style?: Record<string, string | number>;

  currentIndex: number;
  orderCode?: string;
}

const techObj = {
  '3D_PRINTING': '3D Printing',
  CNC: 'CNC',
};

export const ShopNewQuoteSteps = ({
  className,
  style,

  currentIndex,
  orderCode,
}: ShopNewQuoteStepsProps) => {
  const nav = useNavigate();
  // orderCode 可能为空，不过不妨碍使用
  const { isLoading, shopOrder } = getUseShopQuoteStoreByCode(orderCode)(s => ({
    shopOrder: s.shopOrder,
    isLoading: s.isLoadingOrComputing,
  }));

  const { isDemoUser } = useGlobalStore(g => ({
    isDemoUser: g.isDemoUser,
  }));

  const getIsDisabled = (step: number) => {
    if (!shopOrder || shopOrder.code !== orderCode) {
      return true;
    }

    if (step === 1) return !(currentIndex > 1);

    const hasFile = get(shopOrder, o => o.items, []).length > 0;
    const hasAddress = get(shopOrder, o => o.delivery.items, []).length > 0;

    if (step === 2) return !hasAddress || !hasFile;
  };

  const items: StepProps[] = [
    // {
    //   title: i18nFormat('Upload your models'),
    // },
    {
      style: { cursor: getIsDisabled(1) ? 'not-allowed' : 'pointer' },
      title: i18nFormat('Configure'),
      onClick: () => {
        if (!getIsDisabled(1)) {
          nav(`/new_shop_quote/${orderCode}`);
        }
      },
    },
    {
      title: i18nFormat('Checkout'),
      style: {
        cursor: getIsDisabled(2) ? 'not-allowed' : 'pointer',
      },
      onClick: () => {
        if (!getIsDisabled(2)) {
          nav(`/new_shop_quote/${orderCode}/checkout`);
        }
      },
    },
  ];

  if (isLoading) {
    items[currentIndex - 1].icon = <LoadingOutlined />;
  }

  const handleTechnologyChange = (value: string) => {
    if (value === 'CNC') {
      window.location.href = '/auth/sign-up?NewQuoteAfterRegister=true';
    }
  };

  return (
    <div
      id="ShopNewQuoteSteps"
      className={cn(className, styles.container)}
      style={style}
    >
      <div className={styles.technologyCategory}>
        Process:{' '}
        {isDemoUser ? (
          <Select
            value={shopOrder?.technology || '3D_PRINTING'}
            onChange={handleTechnologyChange}
            style={{ width: 150 }}
            options={Object.entries(techObj).map(([key, value]) => ({
              value: key,
              label: value,
            }))}
          />
        ) : (
          techObj[shopOrder.technology || '3D_PRINTING']
        )}
      </div>
      <Steps size="small" current={currentIndex - 1} items={items} />
    </div>
  );
};

ShopNewQuoteSteps.displayName = 'ShopNewQuoteSteps';
