import { CaretDownOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import { i18nDateTimeFormat, i18nFormat } from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { Button, Checkbox, Divider, Modal, Space, Spin } from 'antd';
import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';

import {
  getUseShopQuoteStoreByCode,
  ShopOrderQuoteStoreModifiers,
  useShopExpressStore,
} from '@/stores';

import { CouponRedeemInput } from '../../../../../coupon/components/redeem/CouponRedeemInput';
import { useAppNavigate } from '../../../../../shared';
import { ShopQuoteMinOrderFeeTips } from '../../ShopQuoteEditor/ShopQuoteEditorSummary/ShopQuoteMinOrderFeeTips';
import { ShopQuoteCheckoutContinueToPayBtn } from '../ShopQuoteCheckoutContinueToPayBtn';
import { ShopQuoteCouponSelect } from '../ShopQuoteCouponSelect';
import { ShopQuoteItemReviewTable } from '../ShopQuoteItemReviewTable';
import styles from './index.module.less';
import { ShopQuoteTotalTips } from './ShopQuoteTotalTips';

export interface ShopQuoteCheckoutSummaryProps {
  className?: string;
  style?: Record<string, string | number>;

  orderCode: string;
}

export const ShopQuoteCheckoutSummary = ({
  className,
  style,

  orderCode,
}: ShopQuoteCheckoutSummaryProps) => {
  const useShopQuoteStore = getUseShopQuoteStoreByCode(orderCode);
  useEffect(() => {
    useShopQuoteStore.setState({
      isAgreeTermOfService: true,
    });
  }, []);
  const {
    isLoading,
    shopOrder,
    allowEdit,
    shopOrderPrice,
    shopOrderQuotation,
    isAgreeTermOfService,
  } = useShopQuoteStore();
  const nav = useAppNavigate();
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const { expressList } = useShopExpressStore(s => ({
    expressList: s.shopExpresses,
  }));

  const isRfq = shopOrderPrice && shopOrderPrice.status !== 'OK';

  /** 当前没有报价，还需要等待人工报价 */
  const renderRfqSummary = () => {
    if (!isRfq) return <></>;

    let txt = '';

    const [reason] = S.get(shopOrderQuotation, s => s.delivery.reason, []);

    if (S.get(shopOrderQuotation, q => q.production.status) == 'COMPUTING') {
      txt = S.getShopOrderQuotationRfqDetailReason('computing');
    }

    if (reason && reason.type) {
      txt = S.getShopOrderQuotationRfqDetailReason(reason.type);
    }

    if (
      isRfq &&
      typeof S.get(shopOrderPrice, s => s.delivery.price.amount) !== 'number'
    ) {
      txt = S.getShopOrderQuotationRfqDetailReason('large-delivery-price');
    }

    if (!allowEdit) {
      txt =
        'Thanks for your request, our team of engineers will review your part and email you a quote that includes pricing, lead time, and other important details in 24 hours';
    }

    return <span>{i18nFormat(txt)}</span>;
  };

  const updateAt = useMemo(() => {
    if (!shopOrder) return '-';
    const t = S.get(shopOrder, s => s.updatedAt);
    const i18_t = i18nDateTimeFormat(t);
    return i18_t;
  }, [shopOrder, location.href]);
  /** 当前仍有选项未完毕，还是看的是多个可选报价项 */
  const renderQuotationSummary = () => {
    return <></>;
  };

  /** 当前选项填写完毕，直接看的最终价格 */
  const renderPriceSummary = () => {
    const currency = S.get(shopOrderPrice, s => s.price.currency);
    const minOrderFee = S.get(
      shopOrderPrice,
      o => o.production.minProductionFee.amount,
    );
    const isProductionRfq = S.get(
      shopOrderPrice,
      p => p.production.status !== 'OK',
    );

    const spining =
      isLoading ||
      (S.ShopOrderAccessors.isReadyToCalcPrice(shopOrder)
        ? !shopOrderPrice
        : false) ||
      S.get(shopOrderQuotation, s => s.status === 'COMPUTING') ||
      S.get(shopOrderPrice, s => s.status) === 'COMPUTING';

    return (
      <>
        <div className={styles.infoItem}>
          <span>
            {i18nFormat('Subtotal')}:&nbsp;
            <CaretDownOutlined />
          </span>
          <span>{shopOrderPrice && shopOrderPrice.subTotal}</span>
        </div>
        <div className={styles.subInfoItem}>
          <span>{i18nFormat('Production Fee')}:</span>
          <span>{shopOrderPrice && shopOrderPrice.productionFee}</span>
        </div>
        <div className={styles.subInfoItem}>
          <span>
            {i18nFormat('Minimum Order Fee')}:&nbsp;
            <ShopQuoteMinOrderFeeTips />
          </span>
          <span>
            {isProductionRfq
              ? '-'
              : S.toPriceStringWithOption(minOrderFee, {
                  currency,
                  rfq: isProductionRfq,
                })}
          </span>
        </div>
        <div className={styles.infoItem}>
          <span>{i18nFormat('Shipping')}:</span>
          <span>
            {spining ? (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                {S.getCurrencySymbolByType(currency)}
                <Spin
                  size="small"
                  indicator={<Loading3QuartersOutlined spin />}
                  style={{ marginLeft: 4, marginRight: 4 }}
                />
                Calculating Prices
              </span>
            ) : (
              shopOrderPrice && shopOrderPrice.shipping
            )}
          </span>
        </div>
        <div className={styles.infoItem}>
          <span>{i18nFormat('Discount')}:</span>
          <span>{shopOrderPrice && shopOrderPrice.discount}</span>
        </div>
      </>
    );
  };
  const renderOrderTotalPrice = () => {
    const spining =
      isLoading ||
      (S.ShopOrderAccessors.isReadyToCalcPrice(shopOrder)
        ? !shopOrderPrice
        : false) ||
      S.get(shopOrderQuotation, s => s.status === 'COMPUTING') ||
      S.get(shopOrderPrice, s => s.status) === 'COMPUTING';

    const currency = S.get(shopOrderPrice, s => s.price.currency);

    return (
      <div className={styles.orderTotalPrice}>
        <div className={styles.total}>
          <span>{i18nFormat('Total')}</span>
          <span>({i18nFormat('VAT excluded')})</span>
          <ShopQuoteTotalTips />
        </div>
        <span className={styles.price}>
          {spining ? (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {S.getCurrencySymbolByType(currency)}
              <Spin
                indicator={<Loading3QuartersOutlined spin />}
                style={{ marginLeft: 4, marginRight: 4 }}
              />
              Calculating Prices
            </span>
          ) : (
            shopOrderPrice && shopOrderPrice.total
          )}
        </span>
      </div>
    );
  };

  return (
    <div
      style={style}
      id="ShopQuoteCheckoutSummary"
      className={cn(className, styles.container)}
    >
      <div className={styles.info}>
        <h3 className={styles.title}>{i18nFormat('Order Summary')}</h3>
        <Divider />
        <div className={styles.orderCode}>
          {i18nFormat('Order Number')}: {shopOrder.code}
        </div>
        <Space direction="vertical" style={{ width: '100%' }} size={4}>
          <div className={styles.infoItem}>
            <span>{i18nFormat('Order Status')}:</span>
            <span>{i18nFormat(S.getOrderStatus(shopOrder.status))}</span>
          </div>
          <div className={styles.infoItem}>
            <span>
              {i18nFormat('Checkout {0} parts').replace(
                '{0}',
                shopOrder.items.length,
              )}
              :
            </span>
            <Button
              type="link"
              size="small"
              style={{ padding: 0, color: '#1890ff' }}
              onClick={() => setIsReviewModalOpen(true)}
            >
              {i18nFormat('Review parts')}
            </Button>
          </div>
          <div className={styles.infoItem}>
            <span>{i18nFormat('Lead Time')}:</span>
            <span>
              {S.getShopOrderLeadTimes(shopOrder, expressList).leadTimeStr}
            </span>
          </div>
          <div className={styles.infoItem}>
            <span>{i18nFormat('EST Delivery')}:</span>
            <span>
              {/* {S.getShopOrderLeadTimes(shopOrder, expressList).estDeliveryStr} */}
              {updateAt}
            </span>
          </div>
        </Space>
        <Divider />
        <Space direction="vertical" style={{ width: '100%' }}>
          {S.ShopOrderAccessors.isReadyToCalcPrice(shopOrder)
            ? renderPriceSummary()
            : renderQuotationSummary()}
          {renderRfqSummary()}
        </Space>
        {renderOrderTotalPrice()}
      </div>

      {!isRfq && (
        <div className={styles.coupons}>
          <ShopQuoteCouponSelect orderCode={orderCode} />
          <CouponRedeemInput
            onSuccess={(couponCode, coupon) => {
              if (
                !ShopOrderQuoteStoreModifiers.validateCouponAndReturnErrorMsgByOrderCode(
                  orderCode,
                  coupon,
                )
              ) {
                ShopOrderQuoteStoreModifiers.selectShopOrderCoupon(
                  orderCode,
                  couponCode,
                );
              }
            }}
          />
        </div>
      )}
      <Checkbox
        checked={isAgreeTermOfService}
        className={styles.agreeTermOfService}
        onChange={e => {
          useShopQuoteStore.setState({
            isAgreeTermOfService: e.target.checked,
          });
        }}
      >
        {i18nFormat("I agree to Unionfab's")}&nbsp;
        <a onClick={() => nav.navigateToPdfPageNewTab('csa')}>
          {i18nFormat('Customer Service Agreement')}
        </a>
      </Checkbox>
      <div className={cn(styles.submitBtn)}>
        <ShopQuoteCheckoutContinueToPayBtn orderCode={orderCode} />
      </div>
      <Modal
        width="75%"
        closable={false}
        open={isReviewModalOpen}
        title={i18nFormat('Review parts')}
        onCancel={() => setIsReviewModalOpen(false)}
        footer={
          <Button type="primary" onClick={() => setIsReviewModalOpen(false)}>
            Close
          </Button>
        }
      >
        <ShopQuoteItemReviewTable orderCode={orderCode} />
      </Modal>
    </div>
  );
};

ShopQuoteCheckoutSummary.displayName = 'ShopQuoteCheckoutSummary';
