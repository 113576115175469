import { PaperClipOutlined } from '@ant-design/icons';
import {
  getFilesByIds,
  getModelDetails,
  i18nFormat,
} from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { D3ModelAvatar } from '@unionfab/ufc-shop-ui-commons';
import { Button, Descriptions, Popover, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import cn from 'classnames';
import { includes } from 'lodash-es';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { Ellipsis } from '@/commons/components/Element/ellipsis';
import { UfcFileDownloader } from '@/commons/components/file/UfcFileDownloader';

import CNCDescriptions from './CNCDesc';
import styles from './index.module.less';

export const ShopOrderItemReviewTable = ({
  order,
  quotation,
  className,
  priceInfo,
  isQuote,
}: {
  order: S.ShopOrder;
  className?: string;
  priceInfo: S.ShopOrderPrice;
  quotation?: S.ShopOrderQuotation;
  isQuote: boolean;
}) => {
  const { data: remarkFileMap, isSuccess: hasGotRemarkFiles } = useQuery(
    [`queryRemarkFiles`, order],
    async () => {
      if (order) {
        const fileIds = [];
        order.items.forEach(item => {
          (item.remark?.fileIds || []).forEach(i => i && fileIds.push(i));
          (item?.cncRequirements?.bluePrintFileId || []).forEach(
            i => i && fileIds.push(i),
          );
        });

        if (S.isValidArray(fileIds)) {
          const resp = await getFilesByIds(fileIds);

          return resp;
        }

        return [];
      }
    },
  );

  const renderRemarkFiles = (item: S.ShopOrderItem) => {
    const fileIds = [];
    (
      item.remark?.fileIds ||
      item?.cncRequirements?.bluePrintFileId ||
      []
    ).forEach(i => i && fileIds.push(i));

    const content = fileIds.map(id => {
      if (!hasGotRemarkFiles || !remarkFileMap || !remarkFileMap[id]) {
        return <></>;
      }

      return (
        <div className={styles.file} key={id}>
          <PaperClipOutlined />
          <UfcFileDownloader
            file={remarkFileMap[id]}
            className={styles.downloader}
          />
        </div>
      );
    });

    const remarkFilesCount = (
      <Button
        type="link"
        loading={!hasGotRemarkFiles}
        className={styles.remarkFilesCount}
      >
        {fileIds.length}
      </Button>
    );

    if (fileIds.length == 0) {
      return remarkFilesCount;
    }

    return (
      <Popover
        trigger="click"
        overlayClassName={styles.popover}
        content={hasGotRemarkFiles ? content : null}
      >
        {remarkFilesCount}
      </Popover>
    );
  };

  const renderItemDetails = (item: S.ShopOrderItem) => {
    const {
      skuId,
      lengthUnit,
      modelFileVO,
      handleMethodStr,
      handleRemark,
      nutCount = 0,
      cncRequirements,
    } = item;
    const sku = order.extraVO.skus[skuId];
    const materialId = sku.materialId;
    const material = order.extraVO.materials[materialId];
    const materialName = `${material.materialType.name}-${material.name}`;

    const unitStr = S.getLengthUnitDesc(lengthUnit);
    const { sizeXStr, sizeYStr, sizeZStr } = getModelDetails(modelFileVO);

    const colorDesc = S.get(handleRemark, h => h.desc, '');

    const orderIsCNC = order.technology === 'CNC';
    const CategoryIsCNC = includes(material.materialType?.categoryName, 'CNC');
    const holeTitle = CategoryIsCNC
      ? i18nFormat('Threads and Tapped Holes')
      : i18nFormat('Inserts');

    const finishName = handleMethodStr.concat(
      colorDesc.length > 0 ? `-${colorDesc}` : '',
    );

    return (
      <div className={styles.itemDetail}>
        <Ellipsis maxWidth="100%">{modelFileVO.name}</Ellipsis>
        <div className={styles.detailContent}>
          <span className={styles.avatar}>
            <D3ModelAvatar
              size={80}
              model={modelFileVO}
              key={modelFileVO.id}
              unit={lengthUnit}
            />
          </span>
          <div className={styles.descriptions}>
            {orderIsCNC ? (
              <CNCDescriptions
                m={materialName}
                fs={finishName}
                lt={sku.leadTimeStrFromHours}
                cncRequirements={cncRequirements}
                renderedFiles={renderRemarkFiles(item)}
                n={item.remark?.remark}
              />
            ) : (
              <>
                <Descriptions column={1}>
                  <Descriptions.Item label={i18nFormat('Dimension')}>
                    {`${sizeXStr}*${sizeYStr}*${sizeZStr} ${unitStr}`}
                  </Descriptions.Item>
                  <Descriptions.Item label={i18nFormat('Material')}>
                    {materialName}
                  </Descriptions.Item>
                  <Descriptions.Item label={i18nFormat('Finish')}>
                    {finishName}
                  </Descriptions.Item>
                  <Descriptions.Item label={i18nFormat('Lead Time')}>
                    {sku.leadTimeStrFromHours}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions column={1}>
                  {!CategoryIsCNC && (
                    <Descriptions.Item label={holeTitle}>
                      {nutCount}
                    </Descriptions.Item>
                  )}
                  <Descriptions.Item label={i18nFormat('Notes')}>
                    <Typography.Paragraph
                      style={{ margin: 0, color: 'inherit' }}
                      ellipsis={{ rows: 2, expandable: true }}
                    >
                      {item.remark?.remark}
                    </Typography.Paragraph>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18nFormat('Technical drawing')}>
                    {renderRemarkFiles(item)}
                  </Descriptions.Item>
                </Descriptions>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const columns: ColumnsType<S.ShopOrderItem> = [
    {
      width: '60%',
      title: i18nFormat('Item Details'),
      render: renderItemDetails,
    },
    {
      width: '10%',
      title: i18nFormat('Per'),
      render: (i: S.ShopOrderItem) => {
        // 订单下的价格
        if (isQuote === false) {
          const itemPrice = S.get(
            order.price,
            p => p.production.items,
            [],
          ).find(v => v.id === i.id);
          const amount = S.get(itemPrice, i => i.setPrice.amount);

          return S.toPriceStringWithOption(amount, {
            rfq: false,
            currency: order.requirements.quotation.currency,
          });
        }
        if (priceInfo) {
          if (S.get(priceInfo, p => p.production.status) !== 'OK') return 'RFQ';

          const itemPrice = S.get(priceInfo, p => p.production.items, []).find(
            v => v.id === i.id,
          );
          const amount = S.get(itemPrice, i => i.setPrice.amount);

          return S.toPriceStringWithOption(amount, {
            rfq: false,
            currency: order.requirements.quotation.currency,
          });
        }

        const itemPrice = (quotation?.production.items || []).find(
          v => v.id === i.id,
        );
        if (S.get(itemPrice, ip => ip.status) !== 'OK') return 'RFQ';
        return S.toPriceStringWithOption(itemPrice?.price?.amount, {
          rfq: false,
          currency: order.requirements.quotation.currency,
        });
      },
    },
    {
      width: '10%',
      title: i18nFormat('Quantity'),
      render: (i: S.ShopOrderItem) => `* ${i.printCount}`,
    },
    {
      width: '10%',
      title: i18nFormat('Price'),
      render: (i: S.ShopOrderItem) => {
        // 订单下的价格
        if (isQuote === false) {
          const itemPrice = S.get(
            order.price,
            p => p.production.items,
            [],
          ).find(v => v.id === i.id);
          const amount = S.get(itemPrice, i => i.price.amount);
          return S.toPriceStringWithOption(amount, {
            rfq: false,
            currency: order.requirements.quotation.currency,
          });
        }
        if (priceInfo) {
          if (S.get(priceInfo, p => p.production.status) !== 'OK') return 'RFQ';

          const itemPrice = S.get(priceInfo, p => p.production.items, []).find(
            v => v.id === i.id,
          );
          const amount = S.get(itemPrice, i => i.price.amount);

          return S.toPriceStringWithOption(amount, {
            rfq: false,
            currency: order.requirements.quotation.currency,
          });
        }

        const itemPrice = (quotation?.production.items || []).find(
          v => v.id === i.id,
        );
        if (S.get(itemPrice, ip => ip.status) !== 'OK') return 'RFQ';
        return S.toPriceStringWithOption(itemPrice?.price.amount, {
          rfq: false,
          currency: order.requirements.quotation.currency,
        });
      },
    },
  ];

  return (
    <>
      <div className={cn(styles.orderItemReviewTable, className)}>
        <Table
          rowKey="id"
          columns={columns}
          pagination={false}
          tableLayout="fixed"
          dataSource={order ? order.items : []}
        />
      </div>
    </>
  );
};
