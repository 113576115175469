import * as S from '@unionfab/ufc-shop-commons';
import { i18nDateTimeFormat, i18nFormat } from '@unionfab/ufc-shop-commons';
import cn from 'classnames';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useCNCOptions from '../../../../../../../ufc-shop-commons/src/stores/quote/shop-order-quote/useCNCOptions';
import {
  getUseShopQuoteStoreByCode,
  ShopOrderQuoteStoreModifiers,
} from '../../../../../stores';
import { ShopNewQuoteSteps } from '../../../components/info/ShopNewQuoteSteps';
import { QuoteFilesUploader } from '../../../components/uploader';
import styles from './index.module.less';
import { ShopQuoteEditorSummary } from './ShopQuoteEditorSummary';
import { ShopQuoteItemsTable } from './ShopQuoteItemsTable';

export interface ShopQuoteEditorProps {
  className?: string;
  style?: Record<string, string | number>;

  onAnonymousLoginOrRegister?: () => void;
  onAnonymousLoginOrRegisterCancel?: () => void;
}

export const ShopQuoteEditor = ({
  className,
  style,
  onAnonymousLoginOrRegister,
  onAnonymousLoginOrRegisterCancel,
}: ShopQuoteEditorProps) => {
  const leftRef = useRef(null);

  const navigate = useNavigate();
  const orderCode = useParams().orderCode;

  const { shopOrder, allowEdit } = getUseShopQuoteStoreByCode(orderCode)(s => ({
    allowEdit: s.allowEdit,
    shopOrder: s.shopOrder,
  }));

  const { setOpts: setCNCOpts, options: cncOpts } = useCNCOptions();

  useEffect(() => {
    if (
      shopOrder &&
      !S.UnpaidInquiryOrderStatusList.includes(shopOrder.status)
    ) {
      navigate(-1);
    }
  }, [shopOrder]);

  const updateAt = useMemo(() => {
    if (!shopOrder) return '-';
    const t = S.get(shopOrder, s => s.updatedAt);
    const i18_t = i18nDateTimeFormat(t);
    return i18_t;
    if (i18_t && dayjs(i18_t).isValid()) {
      return dayjs(i18_t).format('MMM D,YYYY');
    }

    return '-';
  }, [shopOrder, location.href]);

  return (
    <div
      id="ShopQuoteEditor"
      className={cn(className, styles.container)}
      style={style}
    >
      <div className={styles.top}>
        <ShopNewQuoteSteps currentIndex={1} orderCode={orderCode} />
      </div>
      <div className={styles.body}>
        <div className={styles.left} ref={leftRef}>
          <div className={styles.table}>
            <ShopQuoteItemsTable orderCode={orderCode} />
          </div>
          <div className={styles.uploader}>
            <QuoteFilesUploader
              disabled={!allowEdit}
              uploaderId={'quote-editor-d3model-uploader'}
              onBatchUploadSuccess={async (files: S.D3ModelFile[]) => {
                let storedCNCOpts = cncOpts;
                if (
                  shopOrder.technology === 'CNC' &&
                  Object.keys(cncOpts).length === 0
                ) {
                  const res: any =
                    await S.getUfcShopComposedApi().shopOrderQueryApi.getCNCOptions();
                  setCNCOpts(res.cnc);
                  storedCNCOpts = res.cnc;
                }
                // 将文件添加到 Store 内
                await ShopOrderQuoteStoreModifiers.addOrUpdateOrderItemByFile(
                  orderCode,
                  files,
                  () => {},
                  storedCNCOpts,
                );
              }}
            />
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.quoteInfo}>
            <div className={styles.quoteInfoItem}>
              <div>{i18nFormat('Quote Code')}:</div>
              <div>{orderCode}</div>
            </div>
            <div className={styles.quoteInfoItem}>
              <div>{i18nFormat('Order Status')}:</div>
              <div>{S.getOrderStatus(shopOrder.status)}</div>
            </div>

            <div className={styles.quoteInfoItem}>
              <div>{i18nFormat('Last Update')}:</div>
              <div>{updateAt}</div>
            </div>
          </div>
          <ShopQuoteEditorSummary
            orderCode={orderCode}
            className={styles.quoteSummary}
            onAnonymousLoginOrRegister={onAnonymousLoginOrRegister}
            onAnonymousLoginOrRegisterCancel={onAnonymousLoginOrRegisterCancel}
          />
        </div>
      </div>
    </div>
  );
};

ShopQuoteEditor.displayName = 'ShopQuoteEditor';
