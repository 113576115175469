import { LoadingOutlined, RightCircleOutlined } from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Popover, Steps } from 'antd';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { useMemo, useEffect } from 'react';

import { StepTimeKey, useOrderDetailsStore } from '@/stores';

import styles from './index.module.less';
import { OrderCurrentStep } from './OrderCurrentStep';
import {
  getUseShopQuoteStoreByCode,
  useShopExpressStore,
  loadOrderInShopEditorStore,
} from '@/stores';
const { Step } = Steps;

const shortFormatTemp = 'MMM D';
const formatTemp = 'dddd, MMM. Do';

interface OrderEstDeliveryAndStepsProps {
  orderCode: string;
}

export const OrderEstDeliveryAndSteps = ({
  orderCode,
}: OrderEstDeliveryAndStepsProps) => {
  const { orderStepTimeMap, loadingOrder, order } = useOrderDetailsStore();
  const useShopQuoteStore = getUseShopQuoteStoreByCode(orderCode);
  useEffect(() => {
    const loadData = async () => {
      if (order) {
        await loadOrderInShopEditorStore(orderCode);
        await useShopExpressStore
          .getState()
          .loadShopExpresses(order.deliver.deliverAddress[0].country);
      }
    };

    loadData();
  }, [orderCode, order]);

  const { shopOrder } = useShopQuoteStore();
  const { expressList } = useShopExpressStore(s => ({
    expressList: s.shopExpresses,
  }));
  const getStepTime = (k: StepTimeKey, temp?: string) => {
    if (isNil(orderStepTimeMap[k])) {
      return '-';
    }
    if (k === 'deliveryTime') {
      return (
        S.getShopOrderLeadTimes(shopOrder, expressList)?.estDeliveryStr || '-'
      );
    }

    const date = orderStepTimeMap[k].value;
    if (!dayjs(date).isValid()) {
      return '-';
    }
    return dayjs(date).format(temp || shortFormatTemp);
  };

  const estSteps = useMemo(() => {
    if (loadingOrder || !S.isValid(orderStepTimeMap)) {
      return <></>;
    }

    const stepKeyDescMap: Partial<Record<StepTimeKey, string>> = {
      payTime: 'Pay Time',
      confirmTime: 'Confirm Order',
      processTime: 'In-Process',
      shipmentTime: 'Shipped',
      deliveryTime: 'Delivered',
    };

    if (!orderStepTimeMap.payTime.value) {
      delete stepKeyDescMap.payTime;
    } else {
      delete stepKeyDescMap.confirmTime;
    }

    let current = 0;

    if (
      typeof orderStepTimeMap.deliveryTime !== 'undefined' &&
      !orderStepTimeMap.deliveryTime.est
    ) {
      current = 3;
    } else if (
      typeof orderStepTimeMap.shipmentTime !== 'undefined' &&
      !orderStepTimeMap.shipmentTime.est
    ) {
      current = 2;
    } else if (
      typeof orderStepTimeMap.processTime !== 'undefined' &&
      !orderStepTimeMap.processTime.est
    ) {
      current = 1;
    } else {
      current = 0;
    }

    return (
      <div className={styles.estSteps}>
        <h4>
          {i18nFormat('The estimated time varies with the time of payment')}
        </h4>
        <Steps progressDot current={current}>
          {Object.keys(stepKeyDescMap).map((k: StepTimeKey) => (
            <Step
              key={k}
              description={
                <div className={styles.stepDesc}>
                  <span>{getStepTime(k)}</span>
                  <span>{i18nFormat(stepKeyDescMap[k])}</span>
                </div>
              }
            />
          ))}
        </Steps>
      </div>
    );
  }, [loadingOrder, orderStepTimeMap]);

  const deliverAt = useMemo(() => {
    let label = 'Delivery';

    const deliveryTime = S.get(orderStepTimeMap, o => o.deliveryTime);

    if (deliveryTime && !isNil(deliveryTime.est) && deliveryTime.est) {
      label = i18nFormat('EST Delivery');
    }

    return (
      <div className={styles.deliveryAt}>
        <span>{label}:</span>
        <span>
          {loadingOrder ? (
            <LoadingOutlined style={{ color: '#FFCE00' }} />
          ) : (
            getStepTime('deliveryTime', formatTemp)
          )}
        </span>
        <Popover content={estSteps} placement="bottom" trigger={'click'}>
          <RightCircleOutlined style={{ color: '#FFCE00' }} />
        </Popover>
      </div>
    );
  }, [loadingOrder, orderStepTimeMap, shopOrder, expressList]);

  const deliverMethod = useMemo(() => {
    if (loadingOrder || !order) {
      return <></>;
    }

    const deliverAddress = S.get(order.deliver, d => d.deliverAddress[0]);
    const expressCompany = S.get(deliverAddress, da => da.expressCompany, '-');
    const expressNumber = S.get(
      deliverAddress,
      da => da.expressNumber[0],
      'Update after shipped',
    );

    return (
      <div className={styles.deliverMethod}>
        <div>
          <span>{i18nFormat('Shipping Method')}:</span>
          <span>{i18nFormat(expressCompany)}</span>
        </div>
        <div>
          <span>{i18nFormat('Tracking Number')}:</span>
          <span>{i18nFormat(expressNumber)}</span>
        </div>
      </div>
    );
  }, [order, loadingOrder]);

  return (
    <div className={styles.container}>
      {deliverAt}
      <OrderCurrentStep />
      {deliverMethod}
    </div>
  );
};
