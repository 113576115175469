import dayjs from 'dayjs';
import { isNil, min } from 'lodash-es';
import { create, GetState, StoreApi, UseBoundStore } from 'zustand';
import { devtools, NamedSet } from 'zustand/middleware';

import {
  fetchOrderQuotation,
  getExpresses,
  getExpressTimingRelations,
  getExpressTimingsToLocation,
  getInquiryChangeRecord,
  getInquiryOrderDetailByCodeForOverseasCustomer,
  getPaymentInfo,
  getShopOrderDetailByCodeForOverseaCustomer,
  getTenantIdFromGConfig,
} from '../../apis';
import * as S from '../../schema';
import {
  getUseShopQuoteStoreByCode,
  loadOrderInShopEditorStore,
} from '../quote/shop-order-quote/useShopQuoteStore';
import { useShopExpressStore } from '../express';

export type StepTimeKey =
  | 'payTime'
  | 'confirmTime'
  | 'processTime'
  | 'shipmentTime'
  | 'deliveryTime'
  | 'completedTime';

export type StepTimeMap = Partial<
  Record<StepTimeKey, { value: string; est: boolean }>
>;

export interface IOrderDetailsState {
  loadingOrder: boolean;
  loadingShopOrder?: boolean;
  order?: S.InquiryOrder;
  shopOrder?: S.ShopOrder;
  paymentInfo?: S.PaymentInfo;
  quotation?: S.OrderQuotationV2;
  shopQuotation?: S.ShopOrderQuotation;
  priceInfo?: S.OrderPaymentPrice;
  orderStepTimeMap: StepTimeMap;

  // derived status
  hasInvoice: boolean;
  shopOrderHasInvoice?: boolean;
  isQuoted: boolean;

  // reload: 如果当前加载订单单号和给定单号一致，是否重新加载订单数据
  loadOrder: (orderCode: string) => Promise<void>;
  loadShopOrder?: (orderCode: string) => Promise<void>;
  loadQuotation: (code?: string) => Promise<void>;
  reloadOrder: () => Promise<void>;
  loadOrderStepsTime: (
    order: S.InquiryOrder,
    paymentInfo?: S.PaymentInfo,
  ) => Promise<StepTimeMap>;
}

interface Store {
  set: NamedSet<IOrderDetailsState>;
  get: GetState<IOrderDetailsState>;
}

export const useOrderDetailsStore: UseBoundStore<
  StoreApi<IOrderDetailsState>
> = create<IOrderDetailsState>()(
  devtools(
    (set, get) => {
      const s: Store = { set, get };

      const loadOrder = async (orderCode: string) =>
        await _loadOrder(s, orderCode);
      const loadShopOrder = async (orderCode: string) =>
        await _loadOrderV2(s, orderCode);
      const reloadOrder = async () => await _reloadOrder(s);
      const loadQuotation = async () => {
        await _loadQuotation(s);
      };
      const loadOrderStepsTime = async (order, paymentInfo) =>
        await _loadOrderStepsTime(order, paymentInfo);

      return {
        isQuoted: false,
        hasInvoice: false,
        shopOrderHasInvoice: false,
        loadingOrder: false,
        loadingShopOrder: false,
        orderStepTimeMap: {},
        priceInfo: { subtotal: 0, total: 0, discount: 0, shipping: 0 },

        loadOrder,
        loadShopOrder,
        reloadOrder,
        loadQuotation,
        loadOrderStepsTime,
      };
    },
    { name: 'orderDetailsStore' },
  ),
);

const _reloadOrder = async (s: Store) => {
  const { order: prev } = s.get();
  if (prev?.code) {
    await _loadOrder(s, prev.code);
  } else {
    console.warn('illegal state: no order to be reload');
  }
};

const _loadOrderV2 = async (s: Store, orderCode: string) => {
  try {
    s.set({ loadingShopOrder: true });

    const order = await getShopOrderDetailByCodeForOverseaCustomer(orderCode);

    const statuses = _deriveOrderStatusV2(order);

    const quotation = order.quotation;

    s.set({
      shopOrder: order,
      ...statuses,
      loadingShopOrder: false,
      shopQuotation: quotation,
    });
  } catch (_e) {
    s.set({ loadingShopOrder: false });

    console.error('useOrderDetailsStore>>_loadOrder', _e);
  }
};

const _loadOrder = async (s: Store, orderCode: string) => {
  try {
    s.set({ loadingOrder: true });

    const order = await getInquiryOrderDetailByCodeForOverseasCustomer(
      orderCode,
      getTenantIdFromGConfig(),
    );

    if (!!order) {
      const statuses = _deriveOrderStatus(order);

      const paymentInfo = await _loadPaymentInfo(s, order);
      const quotation = await _loadQuotation(s, orderCode);
      const orderStepTimeMap = await _loadOrderStepsTime(order, paymentInfo);

      const priceInfo = calcOrderPaymentInfo(order, quotation);

      s.set({
        ...statuses,
        paymentInfo,
        priceInfo,
        orderStepTimeMap,
      });
    }

    s.set({ order, loadingOrder: false });
  } catch (_e) {
    s.set({ loadingOrder: false });

    console.error('useOrderDetailsStore>>_loadOrder', _e);
  }
};

const _loadQuotation = async (s: Store, orderCode?: string) => {
  const order = s.get().order;
  const code = orderCode || s.get().order?.code;

  if (code) {
    const quotation = await fetchOrderQuotation(code);
    s.set({ quotation });

    return quotation;
  } else {
    console.error('illegal state - loadQuotation: order not found', order);
  }
};

const _loadPaymentInfo = async (s: Store, order?: S.InquiryOrder) => {
  const _order = s.get().order || order;

  if (!_order) {
    console.warn('illegal state, order not loaded');
    return;
  }

  const paymentInfo = await getPaymentInfo(_order.id!);

  return paymentInfo;
};

// 从订单衍生的状态
const _deriveOrderStatus = (order: S.InquiryOrder) => {
  let hasInvoice = false;
  let isQuoted = false;

  switch (order.status) {
    case 'WAIT_SUBMIT':
    case 'WAIT_REVIEW':
    case 'WAIT_CUSTOM_CONFIRM':
    case 'ORDER_CLOSED':
      hasInvoice = false;
      break;
    default:
      hasInvoice = true;
  }

  switch (order.status) {
    case 'WAIT_SUBMIT':
    case 'WAIT_REVIEW':
    case 'WAIT_CUSTOM_CONFIRM':
    case 'ORDER_CLOSED':
      isQuoted = false;
      break;
    default:
      isQuoted = true;
  }

  return {
    hasInvoice,
    isQuoted,
  };
};

const _deriveOrderStatusV2 = (order: S.ShopOrder) => {
  let shopHasInvoice = false;

  switch (order.status) {
    case 'WAIT_SUBMIT':
    case 'WAIT_REVIEW':
    case 'WAIT_CUSTOM_CONFIRM':
    case 'ORDER_CLOSED':
      shopHasInvoice = false;
      break;
    default:
      shopHasInvoice = true;
  }

  return {
    shopHasInvoice,
  };
};

const calcOrderPaymentInfo = (
  order?: S.InquiryOrder,
  quotation?: S.OrderQuotationV2,
) => {
  let subtotal: number | undefined;
  let shipping: number | undefined;
  let total: number | undefined;
  let discount: number | undefined;

  const minProductCostFee =
    S.get(quotation, q => q.minProductCostFee) ||
    S.get(order, o => o.price.minProductCostFee) ||
    0;

  if (!!order) {
    const selectedShippingAmount = S.calcOrderShipQuotation(
      order.customerShipOption?.expressId,
      quotation?.shipQuotationsRequiresManualQuote,
      quotation?.shipExpressQuotations,
    ).quotation;

    if (order.hasConfirmedByCustomer) {
      subtotal =
        S.get(order, order => order.price.materialFee, 0) +
        S.get(order, o => o.price.minProductCostFee, 0);
      shipping =
        selectedShippingAmount == null
          ? S.get(order, order => order.price.postage)
          : selectedShippingAmount;
      total = S.get(order, order => order.price.totalPriceWithTax);
      discount = S.get(order, order => order.price.discountAmount);

      const coupon = S.get(order, order => order.price.coupon);

      // TODO: fix me (该优惠券的逻辑应该放在后端)
      if (
        coupon?.type === 'FREE_PRINTING' &&
        subtotal &&
        shipping &&
        discount &&
        subtotal + shipping - discount === 50
      ) {
        // 应用了 FREE_PRINTING 的优惠券
        subtotal = discount;
        shipping = 50;
        total = 50;
      }
    } else {
      subtotal = quotation?.subtotal + minProductCostFee;
      shipping = selectedShippingAmount;
      total =
        subtotal != null && shipping != null ? subtotal + shipping : undefined;
      discount = undefined;
    }

    return { total, subtotal, discount, shipping, minProductCostFee };
  }
};

const _loadOrderDeliveryTime = async (
  order: S.InquiryOrder,
  {
    payTime,
    inquirySteps,
  }: { payTime?: string; inquirySteps?: S.InquiryOrderStep[] },
) => {
  if (order) {
    const { deliver, customerShipOption } = order;

    const targetSteps = (inquirySteps || []).find(s => s.label == '确认收货');

    if (!isNil(targetSteps) && targetSteps.updatedAt) {
      return { value: targetSteps.updatedAt, est: false };
    }

    if (customerShipOption && customerShipOption.expressId) {
      const orderDeliverAddress = S.readOrderReceiveAddress(order);

      const expresses = await getExpresses();
      const expressTimingRelation = await getExpressTimingRelations();

      // 发往给定国家支持的时效和物流公司信息
      const timingsToLocation = await getExpressTimingsToLocation(
        orderDeliverAddress.country || '',
        { expresses, expressTimingRelation },
      );

      const targetTiming = (timingsToLocation || []).find(
        i => i.expressId === customerShipOption.expressId,
      );

      if (targetTiming) {
        const m = targetTiming.timingDesc.match(/(\d+)-(\d+).*[dD][aA][yY]s?/);

        const day = Number(min([m[1], m[2]]));

        let deliveryTime: string;

        if (
          !isNil(payTime) &&
          !isNil(order.manualIntervened) &&
          order.manualIntervened // 自动报价取预估时间: 付款日期+1天+生产周期时间+1天+物流服务配置日期的最小日期
        ) {
          const skus = (order.items || []).map(i => i.materialSpu);

          const leadTime = S.getLeadTimeByInquiryOrder(skus);

          deliveryTime = dayjs(payTime)
            .add(2 + day, 'd')
            .add(leadTime, 'h')
            .format();
        } else {
          // 原有发货时间逻辑
          deliveryTime = dayjs(deliver.lastDeliveredAt).add(day, 'd').format();
          // // 1. 获取 store 实例
          // const shopQuoteStore = getUseShopQuoteStoreByCode(order.code);
          // // 2. 加载数据到 store
          // await loadOrderInShopEditorStore(order.code);
          // await useShopExpressStore
          //   .getState()
          //   .loadShopExpresses(order.deliver.deliverAddress[0].country);
          // // 3. 获取加载后的数据
          // const shopOrder = shopQuoteStore.getState().shopOrder;
          // // 4. 直接从 store 获取 expressList
          // const expressList = useShopExpressStore.getState().shopExpresses;
          // 新发货时间逻辑
          // deliveryTime = S.getShopOrderLeadTimes(shopOrder, expressList)
          //   .estDeliveryStr;
        }

        return { value: deliveryTime, est: true };
      }

      return { value: null, est: true };
    }

    return { value: null, est: true };
  }
};

// 计算订单关键节点时间
const _loadOrderStepsTime = async (
  order: S.InquiryOrder,
  paymentInfo?: S.PaymentInfo,
) => {
  const timeMap: StepTimeMap = {
    payTime: { value: null, est: true },
    confirmTime: { value: null, est: true },
    processTime: { value: null, est: true },
    shipmentTime: { value: null, est: true },
    deliveryTime: { value: null, est: true },
    completedTime: { value: null, est: true },
  };

  try {
    // 获取订单状态变更记录
    const originalRecords = await getInquiryChangeRecord(
      order.code,
      'ORDER_STATUS_UPDATED',
    );

    const steps = S.getInquiryOrderStepListFromStatusRecord(
      originalRecords,
      order.isSynergy,
    );

    /** 计算订单支付时间 */
    if (
      order.payStatus == 'COMPLETE_PAY' &&
      !isNil(paymentInfo) &&
      paymentInfo.id
    ) {
      timeMap.payTime = { value: paymentInfo.completedAt, est: false };
      // 订单有支付信息取支付信息内的时间否则取 确认下单 时间
    }

    const confirmStep = (steps || []).find(s => s.label === '确认下单');

    if (!isNil(confirmStep) && confirmStep.updatedAt) {
      timeMap.confirmTime = { value: confirmStep.updatedAt, est: false };
    }

    /** 计算订单处理时间 */
    if (!isNil(order.synergyType) && order.synergyType == 'OUTSOURCE') {
      // 外发订单取 数据处理 时间
      const resp = (steps || []).find(s => s.label === '数据处理');

      if (!isNil(resp) && resp.updatedAt) {
        timeMap.processTime = { value: resp.updatedAt, est: false };
      }
    } else if (!isNil(order.isSynergy) && order.isSynergy) {
      // 外协订单取 待发货 时间
      const resp = (steps || []).find(s => s.label === '质检/打包');

      if (!isNil(resp) && resp.updatedAt) {
        timeMap.processTime = { value: resp.updatedAt, est: false };
      }
    }

    // processTime 时间为空取预估时间
    if (
      isNil(timeMap.processTime.value) &&
      (!isNil(timeMap.payTime.value) || !isNil(timeMap.confirmTime.value))
    ) {
      const time = timeMap.payTime.value || timeMap.confirmTime.value;

      if (dayjs(time).isValid()) {
        // 预估时间
        timeMap.processTime = {
          est: true,
          value: dayjs(time).add(1, 'd').format(),
        };
      }
    }

    /** 计算发货时间 */
    const deliverRecord = (steps || []).find(s => s.label === '发货');

    if (!isNil(deliverRecord) && deliverRecord.updatedAt) {
      // 实际发货时间
      timeMap.shipmentTime = { value: deliverRecord.updatedAt, est: false };
    } else if (
      !isNil(timeMap.payTime.value) &&
      !isNil(order.manualIntervened) &&
      order.manualIntervened // 自动报价取预估时间：付款日期+1天+生产周期时间+1天
    ) {
      const skus = (order.items || []).map(i => i.materialSpu);
      const leadTime = S.getLeadTimeByInquiryOrder(skus);
      const time = dayjs(timeMap.payTime.value).add(2, 'd').add(leadTime, 'h');

      timeMap.shipmentTime = { value: time.format(), est: true };
    } else if (!isNil(order.deliver.lastDeliveredAt)) {
      // 未发货取最晚交期
      timeMap.shipmentTime = {
        est: true,
        value: order.deliver.lastDeliveredAt,
      };
    }

    /** 计算订单送达时间 */
    const deliveryTime = await _loadOrderDeliveryTime(order, {
      inquirySteps: steps,
      payTime: timeMap.payTime.value,
    });
    !isNil(deliveryTime) && (timeMap.deliveryTime = deliveryTime);

    /** 计算订单完成时间 */
    const completedRecord = (steps || []).find(s => s.label == '确认收货');

    if (completedRecord && completedRecord.updatedAt) {
      timeMap.completedTime = { value: completedRecord.updatedAt, est: false };
    }

    return timeMap;
  } catch (_e) {
    console.error('>>>useOrderDetailsStore>>>getOrderStepsTime', _e);
  }
};
